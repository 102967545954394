import Vue from 'vue'
import VueRouter from 'vue-router'
import shantouRouter from './modules/shantou-project'
import guangfaRouter from './modules/guangfa-project'
// import cardBagRouter from './modules/cardBag' // 2024-10-31少平说旧平台卡包，暂停使用
import unionActRouter from './modules/unionAct'
import huaxiaRouter from './modules/huaxia'
Vue.use(VueRouter)

const routes = [
  ...shantouRouter,
  ...guangfaRouter,
  // ...cardBagRouter,
  ...unionActRouter,
  ...huaxiaRouter
]

const router = new VueRouter({
  routes
})

export default router
