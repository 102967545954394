const huaxia = [
  {
    path: '/huaxia/login',
    name: 'huaxiaLogin',
    meta: { title: '登录' },
    component: () => import('@/views/huaxia/login.vue')
  },
  {
    path: '/huaxia/rights',
    name: 'huaxiaRights',
    meta: { title: '权益领取' },
    component: () => import('@/views/huaxia/rights.vue')
  }
]
export default huaxia
